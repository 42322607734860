@font-face {
  font-family: 'Varela Round';
  src: local('Varela_Round'), url('./fonts/Varela_Round/VarelaRound-Regular.ttf')
}

@font-face {
  font-family: 'Nunito Sans';
  src: local('Nunito_Sans'), url('./fonts/Nunito_Sans/NunitoSans-Black.ttf')
}

$theme-colors: (
  "primary": #65C444,
  "secondary": #6c757d,
  "success": #198754,
  "info": #17a2b8,
  "warning": #ffc107,
  "danger": #dc3545,
  "light": #fbfaf8,
  "dark": #353440
);

.fixed-alert {
  position: fixed !important;
  top: 100px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  z-index: 1050 !important; /* Ensure it is above other elements */
  width: auto !important; /* Adjust width as needed */
}


.nunito {
  font-family: 'Nunito Sans', sans-serif !important;

}

.varela {
  font-family: 'Varela Round', sans-serif !important;
}

.btn {
  border-radius: 30px !important;
}

.image-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.shadow-button-green {
  box-shadow: 4px 3px 0px #353440;
  transition: box-shadow 0.3s ease-in-out;
  border-style: solid !important;
  border-color: #353440 !important;
  border-width: 1px !important;
}

.shadow-button-green:hover {
  box-shadow: none;
  background-color: #65C444 !important;
  border: none !important;
}

.shadow-button-grey {
  box-shadow: 4px 3px 0px #353440;
  transition: box-shadow 0.3s ease-in-out;
  border-style: solid !important;
  border-color: #353440 !important;
  border-width: 1px !important;
}

.shadow-button-grey:hover {
  box-shadow: none;
  border: none !important;
}

.image-background {
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.image-background::before {
  position: absolute;
  content: '';
  bottom: -2px;
  left: 0;
  z-index: 1;
  width: 50%;
  height: 75px;
  background-image: linear-gradient(to bottom left, transparent 49.6%, #fbfaf8 50%);
}

.image-background::after {
  position: absolute;
  content: '';
  bottom: -2px;
  right: 0;
  z-index: 1;
  width: 50%;
  height: 75px;
  background-image: linear-gradient(to bottom right, transparent 49.6%, #fbfaf8 50%);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(88, 33, 73, 0.93); /* Adjust the opacity as needed */
}

.text-content {
  position: relative;
  z-index: 2; /* Ensure text is above the overlay */
  color: white; /* Change text color as needed */
  padding: 20px;
}

.image-background-two {
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-two {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(88, 33, 73, 0.93); /* Adjust the opacity as needed */
}

.text-content-two {
  position: relative;
  z-index: 2; /* Ensure text is above the overlay */
  color: white; /* Change text color as needed */
  padding: 20px;
}

.card-hover-shadow {
  transition: box-shadow 0.3s ease;
}

.card-hover-shadow:hover {
  box-shadow: 0 4px 8px rgba(2, 2, 2, 2);
  background-color: #ffffff
}

.modal-content {
  border-radius: 0px !important
}




@import '~bootstrap/scss/bootstrap';
